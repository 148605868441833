
h3 {
    text-align: center;
    color: #413b3b;
    text-transform: uppercase;
    font-size: 80px;
    font-family: 'Zen Tokyo Zoo', cursive;
}
h5 {
    text-align: center;
    color: #82807f;
    margin: 80px auto;
    font-size: 20px;
    width: 50%;
    line-height: 1.7;
}
.box {
    padding: 10px;
    text-align: right;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(86, 86, 86, 0.8);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}
.overlay-visible {
    visibility: visible;
    opacity: 1;
}
.wrapper {
    margin: 70px auto;
    padding: 20px;
    background: #e7e7e7;
    border-radius: 5px;
    width: 80%;
    position: relative;
    transition: all 5s ease-in-out;
}
.wrapper h2 {
    margin-right: 46px;
    margin-top: 0;
    font-size: 20px;
    color: #818181;
}
.wrapper .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}
.wrapper .close:hover {
    color: #06D85F;
}
.wrapper .content {
    max-height: 30%;
    overflow: auto;
}
/*form*/

.container {
    border-radius: 5px;
    background-color: #e7e7e7;
    padding: 20px 0;
}
form label {
    text-transform: uppercase;
    font-weight: 500;
}
input, select, textarea {
    font-family: shamel,sans-serif;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}
input[type="submit"] {
    background-color: #413b3b;
    color: #fff;
    padding: 15px 50px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;
}
