.ripple {
    position: relative;
    overflow: hidden;
    /*border-radius: 50px;*/
}

span.ripple{
    position: absolute;
    border-radius: 50px;
    transform: scale(0);
    animation: ripple 600ms linear;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}
