@font-face {
  font-family: shamel;
  src: url(assets/fonts/shamel.ttf);
  font-display: block;
}

@font-face {
  font-family: shamel-2;
  src: url(assets/fonts/shamel2.ttf);
  font-display: block;
}

*{
  margin: 0;
  padding: 0;
  text-decoration: none;

  /*fix tap select copy mobile mode*/
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}

body {
  font-family: "shamel-2", sans-serif;
  line-height: 1.25;
  background-color: #ececec;
  margin: 3rem 3rem;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  /*margin-top:50px;*/
}

table caption {
  font-size: 1.2em;
  margin: .25em 0 .75em;
}

table tr {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
  cursor: pointer;
}
table tr:hover {
  background: #eaeaea;
}

table th, table td {
  font-size: .9rem;
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .7em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td img { text-align: center; }
@media screen and (max-width: 600px) {

  body {
    margin: .4rem 0;
  }

  table {
    /*display: none;*/
    border: 0;
  }

  table caption { font-size: 1.3em; }

  table thead { display: none; }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    font-weight: bold;
    text-align: left;
  }

  table td:before {
    content: attr(data-label);
    float: right;
    font-weight: normal;
    text-transform: uppercase;
  }

  table td:last-child { border-bottom: 0; }
}