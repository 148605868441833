@media screen and (max-width: 600px) {
    .product {
        width: 100%;
        text-align: right;
        display: block;
    }

    .product .card {
        display: grid;
        grid-template-columns: repeat(2, auto);
        line-height: 2.2rem;
        padding: .8rem 1rem .2rem 1rem;
        background-color: white;
        border-radius: 5px;
        border-bottom: 1px solid #cecece;
    }
    .product .card:hover{
        background-color: #f3f3f3;
    }

    .product .card .name {
        font-size: 15px;
        color: green;
    }

    .product .card .qty {
        font-family: shamel, sans-serif;
        font-size: 20px;
        text-align: left;
    }

    .product .card .qty:before {
        font-size: 12px;
        font-family: shamel-2, sans-serif;
        content: "المتوفر ";
        margin-right: 5px;
        padding-bottom: 5px;
    }

    .product .card .details {
        font-size: 14px;
    }

    .product .card .price {
        font-size: 14px;
        text-align: left;
    }

    .product .card .icon {
        width: 20px;
        height: 20px;
        color: grey;
    }

}