.button {
    font-size: .8em;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.1s ease-out;
    background: #403e3d;
    border-radius: 50px;
}
.delete-btn{
    width: 35px;
}
.width-80{
    width: 80px;
}
.button:hover {
   opacity: .7;
}
.green{
    background-color: green;
}
.orange{
    background-color: #ffb59e;
    color: #481a09;
    font-family: shamel,sans-serif;
}
.red{
    background-color: #c44040;
}

.row{
    display: flex;
}
.block{
    /*display: block;*/
}

.space{
    margin-right: 1rem;
}
.align-left{
    float: left;
}

.w-50{
    width: 50%;
}

.mt-1{
    margin-top: 3rem !important;
}
.pt-1{
    padding-top: .9rem;
}
.pr-1{
    padding-right: .9rem;
}

.center{
    margin-top: 50%;
    text-align: center;
}

.grey{
    color: grey;
}
.grey-light{
    color: #d9d9d9;
}

.bold{
    font-family: shamel,sans-serif;
}

.white{
    background-color: white;
    color: black;
    font-family: shamel,sans-serif;
}

.product {
    width: 100%;
    text-align: right;
    display: none;
}